import { createListenerMiddleware, isRejectedWithValue } from '@reduxjs/toolkit';

import { showNotification } from 'services/globalSlice';

// Combined endpoints with custom messages or a boolean to indicate the presence of a server provided message
const endpoints = {
  // paymentMethod endpoints
  getPaymentClientSecret: true,
  updatePaymentMethod: true,
  // routes endpoints
  optimizeRoute: true,
  // subscription endpoints
  cancelSubscription: true,
  getSubscriptionTypes: true,
  selectSubscription: true,
  updateSubscription: true,
  // user endpoints
  forgotPassword: true,
  login: true,
  register: true,
  resetPassword: true,
  updatePreferences: true,
  updateUser: true,
};

export const rtkErrorListenerMiddleware = createListenerMiddleware();

rtkErrorListenerMiddleware.startListening({
  matcher: isRejectedWithValue,
  effect: async (action, { dispatch }) => {
    const endpointName = action?.meta?.arg?.endpointName;
    const errorConfig = endpoints[endpointName];

    // Check if the endpoint has a custom message or is marked with a true value
    if (errorConfig) {
      if (typeof errorConfig === 'object') {
        // Custom error message
        dispatch(showNotification({
          ...errorConfig,
          type: 'error',
        }));
      } else {
        // Default error message
        dispatch(showNotification({
          message: action?.payload?.data?.appErrors || 'appNotifications:error.generic',
          type: 'error',
        }));
      }
    }
  },
});
